import styled from "styled-components"
import Font16 from "components/Fonts/Font16"
import Font18 from "components/Fonts/Font18"
import { Chip } from "@mui/material"
import { theme } from "theme/theme"
import Button from "../../../components/Buttons/Button"
import { GatsbyImage } from "gatsby-plugin-image"

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  ${theme.breakpoints.down("500")} {
    flex-direction: column;
    margin: 30px 0 10px;
  }
`
export const StyledFont16 = styled(Font16)`
  max-width: 170px;
  ${theme.breakpoints.down("500")} {
    display: flex;
    max-width: 900px;
    gap: 5px;
  }
`

export const StyledChip = styled(Chip)`
  && {
    padding-left: ${({ offerDetails }) => offerDetails && "0"};
    left: ${({ offerDetails }) => offerDetails && "0"};
    &::before {
      content: "";
      position: absolute;
      width: 1200px;
      height: 5px;
      background-color: inherit;
      left: 100%;
    }
  }
`
export const CountryChip = styled(Chip)`
  && {
    padding-left: 0;
    left: auto;
    right: 80px;
  }
`

export const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 99;
  overflow: visible;

  img {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
`

export const AdditionalInformationList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
  ${theme.breakpoints.down("500")} {
    column-gap: 20px;
  }
`
export const AdditionalInformation = styled.li`
  display: flex;
  align-items: center;
  &::before {
    display: none !important;
  }

  svg {
    width: 30px;
    height: 30px;
    color: ${theme.palette.secondary.main};
  }
`
export const AdditionalInformationText = styled(Font18)`
  && {
    display: flex;
    align-items: center;
    margin-left: 10px !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
    ${theme.breakpoints.down("500")} {
      font-size: 14px;
    }
  }
`

export const ApplyButton = styled(Button)`
  && {
    background-color: #ff0077;
    width: 150px;
    height: 50px;
    border-radius: 15px;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;

    &:hover {
      background-color: #ff0077;
    }
  }
`
