import React, { useState } from "react"
import { Grid, Card, Tooltip, useMediaQuery } from "@mui/material"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import GermanFlag from "images/german_flag.svg"
import InfoIcon from "@mui/icons-material/Info"
import Hr from "components/Hr/Hr"
import PropTypes from "prop-types"
import {
  AdditionalInformation,
  AdditionalInformationList,
  AdditionalInformationText,
  ApplyButton,
  CountryChip,
  OptionsWrapper,
  StyledChip,
  StyledFont16,
  StyledGatsbyImage,
} from "./styles"
import { Link, graphql, useStaticQuery } from "gatsby"
import ApplicationFormModal from "components/AppliccationFormModal/ApplicationFormModal"
import { mapAdditionalInformationIcons } from "../../../shared/mapAdditionalInfromationsIcons"
import CheckIcon from "@mui/icons-material/Check"
import { theme } from "../../../theme/theme"
import { companyData } from "../../../constants/companyData"

const languageTooltipContent = (
  <>
    <p>
      Podstawowy (A1): Opiekun/ka zna podstawowe słowa, większość komunikacji
      buduje za pomocą translatora.
    </p>
    <br />
    <p>
      Komunikatywny (A2): Opiekun/ka buduje proste zdania w czasie
      teraźniejszym, zna słownictwo z zakresu opieki jak i życia codziennego.
    </p>
    <br />
    <p>
      Dobry (B1): Opiekun/ka buduje rozbudowane, pełne zdania. Popełnia
      niewielkie błędy stylistyczne lub gramatyczne, zna słownictwo z zakresu
      opieki i życia codziennego.
    </p>
    <br />
    <p>
      Bardzo dobry (B2): Opiekunka buduje rozbudowane, pełne zdania. Używa
      płynnie wielu czasów i zna szerokie i specjalistyczne słownictwo z zakresu
      opieki i życia codziennego.
    </p>
    <br />
    <p>
      Biegły (C1): Opiekun/ka posługuje się językiem płynnie, nie popełnia
      żadnych błędów stylistycznych, zna wszystkie czasy oraz słownictwo
      specjalistyczne. Swobodnie posługuje się regionalnym akcentem.
    </p>
  </>
)

function JobOfferCard(props) {
  const [openModal, setOpenModal] = useState(false)
  const isMd = useMediaQuery(theme.breakpoints.down("md"))

  const data = useStaticQuery(graphql`
    query {
      germanFlag: file(name: { eq: "german_flag" }, extension: { eq: "png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 60
            quality: 100
            backgroundColor: "transparent"
            placeholder: BLURRED
          )
        }
      }
      polishFlag: file(name: { eq: "polish_flag" }) {
        childImageSharp {
          gatsbyImageData(
            width: 60
            backgroundColor: "transparent"
            quality: 100
            placeholder: NONE
          )
        }
      }
    }
  `)
  const germanFlag = data.germanFlag.childImageSharp.gatsbyImageData
  const polishFlag = data.polishFlag.childImageSharp.gatsbyImageData

  const variant = props.orderType === "Stałe zlecenie" ? "primary" : "secondary"
  const {
    title,
    city,
    land,
    language,
    salary,
    sex,
    startDate,
    currency,
    country,
    contractType,
    shortOrderDescription,
    additionalInformation,
    additionalInformationUndefined,
    offerDetails,
  } = props

  const topOptions = [
    { title: "Land", value: land },
    { title: "Miejscowość", value: city },
    { title: "Język", value: language },
    { title: "Wynagrodzenie", value: salary },
    { title: "Płeć podopiecznego", value: sex },
    { title: "Start zlecenia", value: startDate },
    { title: "Rodzaj umowy", value: contractType },
  ].filter(item => item.value)

  const handleClick = () => {
    setOpenModal(true)
  }

  return (
    <Grid
      item
      xs={12}
      key={props.contentful_id}
      sx={
        offerDetails && {
          position: "relative",
          left: "50%",
          transform: "translate(-50%)",
          width: "98vw",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F5FBFC",
          boxShadow: "0 0 0 1vw #F5FBFC",
          padding: "0 24px",
        }
      }
    >
      <Card
        sx={{
          padding: {
            xs: offerDetails ? "90px 0px 30px" : "90px 30px 30px",
          },
          boxShadow: offerDetails && "none",
          maxWidth: "1200px",
          backgroundColor: offerDetails && "#F5FBFC",
        }}
      >
        <StyledChip label={props.orderType} variant={variant} offerDetails />
        <CountryChip
          label={
            <>
              Kraj: <b>{country}</b>
            </>
          }
          variant={variant}
        />
        <StyledGatsbyImage
          image={country === "Polska" ? polishFlag : germanFlag}
          alt={country}
        />
        <Font24>{title}</Font24>
        <OptionsWrapper>
          {topOptions.map(item => {
            if (item.title === "Język") {
              return (
                <StyledFont16>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {item.title}:{" "}
                    <Tooltip title={languageTooltipContent}>
                      <InfoIcon fontSize="small" sx={{ marginLeft: "10px" }} />
                    </Tooltip>
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <GermanFlag />
                    <b>{item.value}</b>
                  </span>
                </StyledFont16>
              )
            }
            if (item.title === "Wynagrodzenie") {
              return (
                <StyledFont16>
                  {item.title}: <br />
                  <b>
                    {item.value} {currency === "PLN" ? "zł" : "€"}
                  </b>
                </StyledFont16>
              )
            }
            if (item.title === "Land") {
              return (
                <StyledFont16>
                  {country === "Polska" ? "Województwo" : "Land"}: <br />
                  <b>{item.value}</b>
                </StyledFont16>
              )
            }
            return (
              <StyledFont16>
                {item.title}: <br />
                <b>{item.value}</b>
              </StyledFont16>
            )
          })}
        </OptionsWrapper>
        <Hr noIndicator mt="15px" mb="15px" />
        <Grid container>
          <Grid item xs={offerDetails || isMd ? 12 : 9}>
            {!offerDetails ? (
              <Font18 ta="justify">
                {shortOrderDescription.shortOrderDescription}
              </Font18>
            ) : null}
            <AdditionalInformationList>
              {additionalInformation &&
                additionalInformation.map(info => {
                  const icon = mapAdditionalInformationIcons(info)
                  return (
                    <AdditionalInformation key={info}>
                      {icon}
                      <AdditionalInformationText>
                        {info}
                      </AdditionalInformationText>
                    </AdditionalInformation>
                  )
                })}
              {additionalInformationUndefined &&
                additionalInformationUndefined.map(info => {
                  return (
                    <AdditionalInformation key={info}>
                      <CheckIcon />
                      <AdditionalInformationText>
                        {info}
                      </AdditionalInformationText>
                    </AdditionalInformation>
                  )
                })}
            </AdditionalInformationList>
          </Grid>
          {isMd && (
            <Hr noIndicator mt="15px" mb="35px" sx={{ width: "100%" }} />
          )}
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="column"
            alignItems={
              offerDetails ? (isMd ? "center" : "flex-start") : "center"
            }
            xs={offerDetails || isMd ? 12 : 3}
            py={0}
          >
            <ApplyButton onClick={handleClick}>Aplikuj</ApplyButton>
            <Font18 mb={0} mt={1} fw={900}>
              Zadzwoń do nas
            </Font18>
            <a
              style={{
                color: "#544485",
                fontWeight: "900",
                marginBottom: "20px",
              }}
              href={`tel:${companyData.telephone}`}
            >
              {companyData.telephoneFormatted}
            </a>
            {offerDetails ? null : (
              <Link to={`/dla-opiekunki/oferta/${props.contentful_id}`}>
                <Font18 variant="secondary">Czytaj więcej</Font18>
              </Link>
            )}
          </Grid>
        </Grid>
      </Card>
      {openModal && (
        <ApplicationFormModal
          offerId={`Wysłano z oferty: ${title} | ${city} | ${startDate}`}
          openModal={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </Grid>
  )
}

JobOfferCard.propTypes = {
  title: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  land: PropTypes.string,
  language: PropTypes.string,
  salary: PropTypes.number.isRequired,
  sex: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  currency: PropTypes.oneOf(["EUR", "PLN"]).isRequired,
  country: PropTypes.oneOf(["Polska", "Niemcy"]).isRequired,
  contractType: PropTypes.string,
  additionalInformation: PropTypes.arrayOf(PropTypes.string),
  additionalInformationUndefined: PropTypes.string,
  offerDetails: PropTypes.bool,
}

export default JobOfferCard
