import React from "react"
import {
  DirectionsBike,
  DirectionsCar,
  Grass,
  LocationCity,
  Accessible,
  Hotel,
  Wc,
  ElderlyWoman,
  Tv,
  Wifi,
  SentimentSatisfiedAlt,
  Gite,
  DirectionsWalk,
  NightsStay,
  Business,
  Check,
} from "@mui/icons-material"

export const mapAdditionalInformationIcons = option => {
  switch (option) {
    case "rower do dyspozycji":
      return <DirectionsBike />
    case "samochód do dyspozycji":
      return <DirectionsCar />
    case "wieś":
      return <Grass />
    case "miasto":
      return <LocationCity />
    case "osoba na wózku":
      return <Accessible />
    case "osoba leżąca":
      return <Hotel />
    case "małżeństwo":
      return <Wc />
    case "osoba samotna":
      return <ElderlyWoman />
    case "telewizor":
      return <Tv />
    case "internet":
      return <Wifi />
    case "miła atmosfera":
      return <SentimentSatisfiedAlt />
    case "dom jednorodzinny":
      return <Gite />
    case "osoba chodząca":
      return <DirectionsWalk />
    case "wstawanie nocne":
      return <NightsStay />
    case "blok mieszkaniowy":
      return <Business />

    default:
      return <Check />
  }
}
